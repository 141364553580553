body {
  background: #B1D7F8;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#roof {
  width: 70%;
}

#mintlive {
  width: 45%;
}

#docgif {
  width: 300px;
  height: 300px;
  border-radius: 6px;
}

@media only screen and (max-width: 600px) {
  #roof {
    width: 120%;
  }

  #mintlive {
    width: 250px;
  }

  #docgif {
    width: 250px;
    height: 250px;
  }
  
}
