.App {
  text-align: center;
}

.slider {
  background: #B1D7F8;
  /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
  height: 300px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;
}
.slider::before, .slider::after {
  background: linear-gradient(to right, #B1D7F8 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 300px;
  position: absolute;
  width: 150px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: slide 40s linear infinite;
  animation: slide 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  height: 300px;
  width: 300px;
  margin-left: 30px;

}

.slider .slide img{
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .slider {
    background: #B1D7F8;
    /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
    height: 300px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 350px;
  }
  .slider::before, .slider::after {
    background: linear-gradient(to right, #B1D7F8 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 300px;
    position: absolute;
    width: 25px;
    z-index: 2;
  }
  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  .slider::before {
    left: 0;
    top: 0;
  }
  .slider .slide-track {
    -webkit-animation: slide 40s linear infinite;
            animation: slide 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .slider .slide {
    height: 300px;
    width: 300px;
    margin-left: 30px;
  }
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@-webkit-keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1500px, 0, 0); /* The image width */
  }
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1500px, 0, 0); /* The image width */
  }
}